import Images from "./images";

let test = false;
export const baseurl = !test
  ? "https://int.alistetechnologies.com"
  : "http://localhost:3001";

export const weburl = "https://web.alistetechnologies.com";
export const otaurl = "https://ota.alistetechnologies.com";
export const socketurl = "https://a3.alistetechnologies.com";
export const cloudUrl = "https://subscriptioncloud.alistetechnologies.com";
export const affilatedProductUrl =
  "https://wtro4ntaa8.execute-api.ap-south-1.amazonaws.com/default";
export const mqttDeviceUrl =
  "https://keiozfbox5.execute-api.ap-south-1.amazonaws.com/default";

export const FanModuleSwitchState = {
  MANUAL: 0,
  MODULE: 1,
};

export const StockStores = {
  Office: "Office",
  Bengaluru: "Bengaluru",
  HBEON: "HBEON",
};

export const SMPSTestingDeviceTypes = {
  1: "1 switch",
  2: "2 switch",
  3: "3 switch",
  4: "4+1 switch",
  5: "5 switch",
  "2pf": "2 plus fan",
  "3pf": "3 plus fan",
  "3wave": "3 Wave",
};

export const WarningReparirsLimit = 2;

export const FakeAmplifyConfig = {
  aws_project_region: "ap-south-1",
  aws_cognito_region: "ap-south-1", // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: "", // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: "", // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
  aws_cognito_identity_pool_id: "", // (optional) - Amazon Cognito Identity Pool ID
  aws_mandatory_sign_in: "disable", //
};

export const AmplifyConfig = {
  aws_project_region: "ap-south-1",
  aws_cognito_region: "ap-south-1", // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: "ap-south-1_qZtowoQ5j", // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: "6bfn7oio6s1vvuhkg2hem7pb19", // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
  aws_cognito_identity_pool_id:
    "ap-south-1:147ce4c2-8c6c-4431-ae09-2423cef38fed", // (optional) - Amazon Cognito Identity Pool ID
  aws_mandatory_sign_in: "disable", //
};

export const PubSubConfig = {
  aws_pubsub_region: "ap-south-1",
  aws_pubsub_endpoint:
    "wss://a1pv71f2h6guqz-ats.iot.ap-south-1.amazonaws.com/mqtt",
};

export const TestingType = {
  SMPS: 0,
  Basic: 1,
  Hardware: 2,
  OnlineOffline: 3,
  FanModule: 4,
  Control: 5,
  Repair: 6,
  Problem: 7,
};

export const FanModuleTestingSteps = {
  Select: 0,
  Details: 1,
  VoltageTest: 2,
  FanAppTest: 3,
  FanRegulatorTest: 4,
  Summary: 5,
};

export const SMPSTestingSteps = {
  Register: 0,
  Details: 1,
  Test: 2,
  Summary: 3,
};

export const BasicTestingSteps = {
  Test: 0,
};

export const SyncTestingSteps = {
  Register: 0,
  Details: 1,
  Regulator: 2,
  SwitchTest: 3,
  AppTest: 4,
  Summary: 5,
};

export const EnergISyncTestingSteps = {
  Setup: 0,
  Details: 1,
  Meter: 2,
  Summary: 5,
};

export const OnlineTestingStatus = {
  Testing: 0,
  Failed: 1,
  Passed: 2,
};

export const EnergISyncTestingStatus = {
  Testing: 0,
  Failed: 1,
  Passed: 2,
};

export const ControlSyncSteps = {
  Select: 0,
  Control: 1,
};

export const SwitchPinTestingConfig = {
  numberOfCommands: 5,
  switchDelay: 1000,
  numberOfSwitches: 5,
};

export const AppTestConfig = {
  numberOfCommands: 5,
  numberOfSwitches: 5,
  commandDelay: 1000,
};

export const WifiHealthStatus = {
  Unknown: "Unknown",
  Bad: "Bad",
  Good: "Good",
};

export const RegulatorTestingTime = 5;
export const OnlineOfflineTestingTime = 15 * 60;

export const WifiStrengthTolerance = {
  ESP32: 5,
  ESP8266: 5,
};

export const EnergISyncTestingCommands = {
  ON: 100,
  OFF: 0,
};

export const EnergISyncParameters = {
  VOLTAGE: "v",
  POWER: "p",
  POWER_FACTOR: "pf",
  CURRENT: "c",
};

export const EnergISyncTestingConfig = {
  ReadingsDelay: 7500, // the amount of time we wait after sending command to read readings
  CommandsDelay: 7500, // the amount of time we wait after sending the request to get readings to call the next control command
  NumberOfCycles: 5, // the total number of on-off cycles, the load will be turned on and off this many times
  Range: {
    // expected range of power, this ensures that the relay is working too, cause if the relay is bad both meters can still give the same reading but the load wont change state
    [EnergISyncTestingCommands.ON]: {
      minimumWattage: 20,
      maximumWattage: 10000,
    },
    [EnergISyncTestingCommands.OFF]: {
      minimumWattage: 0,
      maximumWattage: 20,
    },
  },
  Tolerance: {
    // tolerance for each paramter being monitored
    [EnergISyncParameters.VOLTAGE]: 3,
    [EnergISyncParameters.POWER]: 3,
    [EnergISyncParameters.POWER_FACTOR]: 1,
    [EnergISyncParameters.CURRENT]: 3,
  },
};

export const TestingStationEvents = {
  SYNC: "sync",
  STATUS: "status",
  EVENT: "event",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  START_SWITCH_PIN_TEST: "switch_pin_start",
  CURRENT_WIFI: "current_wifi",
  SWITCH_PIN_CONNECTION: "switch_pin_connection",
  SWITCH_PIN_SYNC: "switch_pin_sync",
  SWITCH_PIN_STATUS: "switch_pin_status",
  SWITCH_PIN_EVENT: "switch_pin_event",
  SEND_SWITCH_PIN_COMMAND: "switch_pin_command",
  UPDATE_DEVICES: "update_devices",
  FAN_MODULE_CONNECTION: "fan_module_connection",
  SEND_FAN_MODULE_COMMAND: "fan_module_command",
  FAN_MODULE_EVENT: "fan_module_event",
  FAN_MODULE_SYNC: "fan_module_sync",
  FAN_MODULE_STATUS: "fan_module_status",
};

export const MQTTEvents = {
  DEVICE_SYNC: "e/sync",
  DEVICE_CONN: "e/conn",
  DEVICE_CURRENT_WIFI: "e/current_wifi",
  DEVICE_MESSAGE: "message",
  DEVICE_CONTROL: "control",
  DEVICE_WIFI_DETAILS: "wifi_details",
  DEVICE_STATUS_UPDATE: "status",
  CONNECTED: `$aws/events/presence/connected`,
  DISCONNECTED: `$aws/events/presence/disconnected`,
  SWITCHPIN_TESTER_CONN: "st/conn",
  SWITCHPIN_TESTER_SYNC: "st/sync",
  SWITCHPIN_TESTER_START_TEST: "st/start_test",
  SWITCHPIN_TESTER_WIFI_DETAILS: "st/wifi_details",
  SWITCHPIN_TESTER_CURRENT_WIFI: "st/current_wifi",
  SWITCHPIN_TESTER_CONTROL: "st/control",
  STATUS_UPDATE: "ts/status",
  STATUS_SYNC: "ts/sync",
  ENERGISYNC_READINGS: "es/ev/readings",
  ENERGISYNC_GET_READINGS: "es/readings",
  ENERGISYNC_CONTROL: "es/control",
  ENERGISYNC_COMMAND: "es/command",
  ENERGISYNC_MESSAGE: "es/ev/message",
};

export const EnergISyncFailedReasons = {
  NotApplicable: 0,
  DeviceRestarted: 1,
  TesterRestarted: 2,
  DeviceReadingsNotFound: 3,
  TesterReadingsNotFound: 4,
  ReadingsOutOfRange: 5,
  TesterReadingsOutOfRnage: 6,
  InaccurateReading: {
    [EnergISyncParameters.VOLTAGE]: 7,
    [EnergISyncParameters.CURRENT]: 8,
    [EnergISyncParameters.POWER]: 9,
    [EnergISyncParameters.POWER_FACTOR]: 10,
  },
};

export const EnergISyncFailedReasonsText = {
  [EnergISyncFailedReasons.NotApplicable]: "Passed",
  [EnergISyncFailedReasons.DeviceRestarted]: "Device restarted",
  [EnergISyncFailedReasons.TesterRestarted]: "Tester restarted",
  [EnergISyncFailedReasons.DeviceReadingsNotFound]: "Device readings not found",
  [EnergISyncFailedReasons.TesterReadingsNotFound]: "Tester readings not found",
  [EnergISyncFailedReasons.ReadingsOutOfRange]: "Readings out of range",
  [EnergISyncFailedReasons.TesterReadingsOutOfRnage]: "Tester out of range",
  [EnergISyncFailedReasons.InaccurateReading.v]: "Voltage value incorrect",
  [EnergISyncFailedReasons.InaccurateReading.p]: "Power value incorrect",
  [EnergISyncFailedReasons.InaccurateReading.c]: "Current value incorrect",
  [EnergISyncFailedReasons.InaccurateReading.pf]:
    "Power factor value incorrect",
};

export const UserRoles = {
  SuperAdmin: "Super Admin",
  Admin: "Admin",
  Tester: "Tester",
  HardwareTester: "Hardware Tester",
  OnlineTester: "Online Tester",
  FanModuleTester: "Fan Module Tester",
  Sales: "Sales",
  SalesAdmin: "SalesAdmin",
  Grievance: "Grievance",
  Telecaller: "Telecaller",
  SmartMeter:'SmartMeter',
  LeadHandler: "LeadHandler",
  Dispatch: "Dispatch",
  Assembly: "Assembly",
  Installation: "Installation",
  ExternalTeleCaller: "External TeleCaller",
  Accounts: "Accounts",
  ExternalTester: "External Tester",
  ExternalRepair: "External Repair",
  ExternalMovement: "External Movements",
  Any: "*",
};

export const userListRolesUpdate = [
  { value: UserRoles.SuperAdmin },
  { value: UserRoles.Admin },
  { value: UserRoles.Tester },
  { value: UserRoles.LeadHandler },
  { value: UserRoles.HardwareTester },
  { value: UserRoles.OnlineTester },
  { value: UserRoles.FanModuleTester },
  { value: UserRoles.Sales },
  { value: UserRoles.SalesAdmin },
  { value: UserRoles.Grievance },
  { value: UserRoles.Telecaller },
  { value: UserRoles.SmartMeter },
  { value: UserRoles.Dispatch },
  { value: UserRoles.Assembly },
  { value: UserRoles.Installation },
  { value: UserRoles.ExternalTeleCaller },
  { value: UserRoles.ExternalTester },
  { value: UserRoles.ExternalRepair },
  { value: UserRoles.ExternalMovement },
  { value: UserRoles.Any },
];
export const WiFiOptions = [
  { ws: "THE CULT 4G", wp: "7351875393" },
  { ws: "THE CULT", wp: "98873382165" },
  { ws: "Aliste Automation 2.4G", wp: "98873382165" },
  { ws: "Aliste 4G", wp: "9873382165" },
  { ws: "aliste", wp: "12345678" },
  { ws: "aliste2", wp: "12345678" },
];

export const SMPSReasons = [
  "Other",
  "4.7E",
  "Transformer",
  "1N 4007(M7) Diode",
  "SS55 Diode",
  "Blue LED",
  "DK125",
  "MB10S Bridge Rectifier",
  "PC817",
  "Inductor 10mH",
  "NTC",
  "2K2 0.25W 5%",
  "3k 0.25W 5%",
  "3K3 0.25W 5%",
  "100K 0.5W 5%",
  "680K 0.25W 5%",
  "103/25V 10%",
  "103/1kv",
  "102/25 V",
  "220uF/10V 10%",
  "6.8uf/450v",
  "100uf/16v",
  "102/1KV",
  "470uF/10V",
  "1K 0.25W 5%",
  "TL431",
  "MOV",
  "100nF/25V 10%",
  "10E 0.25W 5%",
];

export const SMPSParts = [
  {
    value: "RESISTOR",
  },
  {
    value: "10k 0.25W 5%",
  },
  {
    value: "330E 0.25W 5%",
  },
  {
    value: "1K 0.25W 5%",
  },
  {
    value: "2K2 0.25W 5%",
  },
  {
    value: "3k 0.25W 5%",
  },
  {
    value: "3K3 0.25W 5%",
  },
  {
    value: "100K 1W 5%",
  },
  {
    value: "10E/0.25W",
  },
  {
    value: "680K 0.25W 5%",
  },
  {
    value: "103/25V 10%",
  },
  {
    value: "103/1kv",
  },
  {
    value: "102/25V 10%",
  },
  {
    value: "100nF/25V 10%",
  },
  {
    value: "220uF/10V 10%",
  },
  {
    value: "6.8/450v",
  },
  {
    value: "470uf/16V",
  },
  {
    value: "100uf/16v",
  },
  {
    value: "102/1kv",
  },
  {
    value: "2.2UF/250VAC",
  },
  {
    value: "3.3UF/250VAC",
  },
  {
    value: "NTC",
  },
  {
    value: "MOV",
  },
  {
    value: "TL431",
  },
  {
    value: "ULN2003",
  },
  {
    value: "1N 4007(M7) Diode ",
  },
  {
    value: "1N 4007(A7) Diode ",
  },
  {
    value: "SS56 Diode ",
  },
  {
    value: "AC6T/G5NB/ALDP",
  },
  {
    value: "G5Q-1-DC5",
  },
  {
    value: "MB10S Bridge Rectifier",
  },
  {
    value: "PC817",
  },
  {
    value: "8PIN IC BASE",
  },
  {
    value: "TLP281-4",
  },
  {
    value: "DK125",
  },
  {
    value: "Inductor 10mH",
  },
  {
    value: "PCB 3+1",
  },
  {
    value: "4.7E",
  },
  {
    value: "Transformer",
  },
  {
    value: "10 PIN 2515 CONNECTOR",
  },
  {
    value: "220K",
  },
  {
    value: "LEONE SPDT RELAY",
  },
  {
    value: "Ferrite Bead",
  },
  {
    value: "LED BLUE",
  },
  {
    value: "ESP8266",
  },
  {
    value: "Header Pins",
  },
  {
    value: "ESP32",
  },
  {
    value: "LM1117",
  },
  {
    value: "G2RL 23 Amps",
  },
  {
    value: "PIC12F683",
  },
  {
    value: "220uF/10V 10%",
  },
  {
    value: "470uf/16V",
  },
  {
    value: "100uf/16v",
  },
  {
    value: "100uF/10v Tantalium Cap",
  },
  {
    value: "Blue LED",
  },
  {
    value: "IR LED",
  },
  {
    value: "IR Reciever",
  },
  {
    value: "104",
  },
  {
    value: "MMBT2222",
  },
  {
    value: "Okay",
  },
  {
    value: "DRY SOLDER",
  },
  {
    value: "FUSE",
  },
  {
    value: "RELAY",
  },
  {
    value: "Other",
  },
];

export const ReapirReasons = [
  "Other",
  "DK125",
  "ESP",
  "TRANSFORMER",
  "MB10S",
  "NTC",
  "FUSE",
  "RELAY",
  "CAPACITOR",
  "DRY SOLDER",
  "DIODE",
  "Base - 100E 0/25W 5%",
  "Base - 330E 0.25W 5%",
  "Base - 1K 0.25W 5%",
  "Base - 10K 0.25W 5%",
  "Base - 100nF/25V 10%",
  "Base - G2RL",
  "Base - G5Q-1-DC5",
  "Base - PC817",
  "Base - PCB 5S",
  "Base - 3 PIN CONNECTOR",
  "Base - 10 PIN 2515 CONNECTOR",
  "Base - 5 PIN 2515 CONNECTOR",
  "Base - 1N 4007(M7) Diode",
  "SMPS - 4.7E",
  "SMPS - Transformer",
  "SMPS - 1N 4007(M7) Diode",
  "SMPS - SS56 Diode",
  "SMPS - Blue LED",
  "SMPS - DK125",
  "SMPS - MB10S Bridge Rectifier",
  "SMPS - PC817",
  "SMPS - Inductor 10mH",
  "SMPS - NTC",
  "SMPS - 2K2 0.25W 5%",
  "SMPS - 3k 0.25W 5%",
  "SMPS - 3K3 0.25W 5%",
  "SMPS - 100K 0.5W 5%",
  "SMPS - 680K 0.25W 5%",
  "SMPS - 103/25V 10%",
  "SMPS - 103/1kv",
  "SMPS - 102/25 V",
  "SMPS - 220uF/10V 10%",
  "SMPS - 6.8uf/450v",
  "SMPS - 100uf/16v",
  "SMPS - 102/1KV",
  "SMPS - 470uF/10V",
  "SMPS - 1K 0.25W 5%",
  "SMPS - TL431",
  "SMPS - MOV",
  "SMPS - 100nF/25V 10%",
  "SMPS - 10E 0.25W 5%",
  "ESP - ESP32",
  "ESP - ESP8266",
  "ESP - 10K",
  "ESP - 1K",
  "ESP - 100NF",
  "ESP - LM1117",
  "ESP - 1UF",
  "ESP - 10UF",
  "Fan - 3.3UF",
  "Fan - 2.2UF",
  "Fan - MMBT2222",
  "Fan - A7 DIODE",
  "Fan - 220K",
  "Fan - MOV",
  "Fan - 2.2E",
  "Fan - ALDP RELAY",
  "Fan - LEONE RELAY",
  "Okay",
];
export const NewRepairResons = ["Ok", "Not OK"];

/**
 * All movements type
 */
export const MovementTransactionsType = {
  Stock: 0,
  DispatchedToSite: 1,
  Electrician: 2,
  Exchanged: 3,
  Installed: 4,
  Deleted: 5,
  Returned: 6,
  Other: 7,
  Restocked: 8,
  Discarded: 9,
};

/**
 * movement types that can be entered by the user
 */

export const MovementsType = {
  Stock: 0,
  DispatchedToSite: 1,
  Electrician: 2,
  Returned: 6,
  Other: 7,
  Restocked: 8,
  Discarded: 9,
};

export const DefaultMovementNames = {
  [MovementsType.Stock]: StockStores.Office,
  [MovementsType.DispatchedToSite]: "Site name",
  [MovementsType.Returned]: StockStores.Office,
  [MovementsType.Other]: "To Name",
};

export const PcbTypes = [{ value: "Base" }, { value: "ESP" }, { value: "Fan" }];

export const smpsReason = ["Ok", "Parts"];

export const DeviceTypes = {
  Sync: "sync",
  EnergISync: "energISync",
  Curtain: "curtain",
  Nova: "nova",
  WeatherSensor: "weatherSensor",
  Invalid: "invalid",
};

export const DeviceCodeByType = {
  [DeviceTypes.Sync]: ["01", "02", "03", "12", "13", "04", "05", "W3"],
  [DeviceTypes.EnergISync]: ["24", "25", "28", "30", "31", "32", "33"],
  [DeviceTypes.Curtain]: ["06"],
  [DeviceTypes.Nova]: ["08"],
  [DeviceTypes.WeatherSensor]: ["41"],
};

export const RoutePaths = {
  Home: "/",
  "SMPS Testing": "/smpsTesting",
  "Basic Test": "/basicTest",
  "Hardware Test": "/hardwareTest",
  "EnergISync Testing": "/energISyncTesting",
  "Sync Hardware Test": "/syncHardwareTest",
  "Online Test": "/onlineTest",
  "Fan Module Test": "/fanModuleTest",
  "Log Repair": "/logRepair",
  "Bulk OTA": "/bulkOTA",
  "Create User": "/createUser",
  Users: "/userList",
  "Control Sync": "/controlSync",
  "Testing Status": "/testingStatus",
  "Swap Device Ids": "/swapDeviceIds",
  "Update WiFi": "/updateWiFi",
  "Discard Device": "/discardDevice",
  "Device Movement": "/movements",
  "Returned Devices": "/returnedDevices",
  "Current Movement Devices": "/currentMovementDevices",
  "PCB Type": "/pcbType",
  "PCB Version": "/pcbVersion",
  Assembler: "/assembler",
  "Device Register": "/deviceRegister",
  "Search House": "/searchHouse",
  "Search Dispatch": "/searchDispatch",
  "Device Type": "/deviceType",
  "Stock Movement": "/StockMovement",
  "Device Id Pdf": "/devicePdf",
  "Testing Station": "/testingStations"
};

export const RoutesConfiguration = [
  {
    linkTo: RoutePaths["Home"],
    image: Images.Logo,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Admin,
      UserRoles.Dispatch,
      UserRoles.Accounts,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.OnlineTester,
      UserRoles.Assembly,
      UserRoles.Sales,
      UserRoles.Installation,
    ],
    toolTip: "Home",
  },
  {
    linkTo: RoutePaths["SMPS Testing"],
    image: Images.MenuImages.SMPSTesting,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
    ],
    toolTip: "SMPS Testing",
  },
  {
    linkTo: RoutePaths["Basic Test"],
    image: Images.MenuImages.BasicTesting,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.OnlineTester,
      UserRoles.ExternalTester,
    ],
    toolTip: "Basic Test",
  },
  {
    linkTo: RoutePaths["Hardware Test"],
    image: Images.MenuImages.HardwareTesting,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
    ],
    toolTip: "Hardware Test",
  },
  {
    linkTo: RoutePaths["EnergISync Testing"],
    image: Images.MenuImages.EnergISyncHardwareTesting,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.ExternalTester,
    ],
    toolTip: "EnergISync Testing",
  },
  {
    linkTo: RoutePaths["Sync Hardware Test"],
    image: Images.MenuImages.HardwareTesting,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.ExternalTester,
    ],
    toolTip: "Sync Hardware Test",
  },
  {
    linkTo: RoutePaths["Online Test"],
    image: Images.MenuImages.OnlineOfflineTesting,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.OnlineTester,
      UserRoles.ExternalTester,
    ],
    toolTip: "Online Test",
  },
  {
    linkTo: RoutePaths["Fan Module Test"],
    image: Images.MenuImages.FanModuleTesting,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
    ],
    toolTip: "Fan Module Test",
    hide: true,
  },
  {
    linkTo: RoutePaths["Log Repair"],
    image: Images.MenuImages.RepairLog,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
    ],
    toolTip: "Log Repair",
  },
  {
    linkTo: RoutePaths["Bulk OTA"],
    image: Images.MenuImages.BulkOTA,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
    ],
    toolTip: "Bulk OTA",
  },
  {
    linkTo: RoutePaths["Create User"],
    image: Images.MenuImages.UserManagement,
    availableFor: [UserRoles.SuperAdmin, UserRoles.Admin],
    toolTip: "Create User",
  },
  {
    linkTo: RoutePaths["Users"],
    image: Images.MenuImages.Users,
    availableFor: [UserRoles.SuperAdmin, UserRoles.Admin],
    toolTip: "Users",
  },
  {
    linkTo: RoutePaths["Control Sync"],
    image: Images.MenuImages.ControlSync,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
    ],
    toolTip: "Control Sync",
  },
  {
    linkTo: RoutePaths["Testing Status"],
    image: Images.MenuImages.TestingStatus,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.ExternalTester,
    ],
    toolTip: "Testing Status",
  },
  {
    linkTo: RoutePaths["Swap Device Ids"],
    image: Images.MenuImages.SwapDeviceIds,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
    ],
    toolTip: "Swap Device Ids",
    hide: true,
  },
  {
    linkTo: RoutePaths["Update WiFi"],
    image: Images.MenuImages.UpdateWiFi,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
    ],
    toolTip: "Update WiFi",
  },
  {
    linkTo: RoutePaths["Discard Device"],
    image: Images.MenuImages.DiscardDevice,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
    ],
    toolTip: "Discard Device",
  },
  {
    linkTo: RoutePaths["Device Movement"],
    image: Images.MenuImages.Movement,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Accounts,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.Dispatch,
      UserRoles.ExternalMovement,
    ],
    toolTip: "Device Movement",
  },
  {
    linkTo: RoutePaths["Returned Devices"],
    image: Images.MenuImages.ReturnedDevices,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.Dispatch,
    ],
    toolTip: "Returned Devices",
  },
  {
    linkTo: RoutePaths["Current Movement Devices"],
    image: Images.MenuImages.CurrentMovement,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.Dispatch,
    ],
    toolTip: "Current Movement Devices",
  },
  {
    linkTo: RoutePaths["PCB Type"],
    image: Images.MenuImages.PcbType,
    availableFor: [UserRoles.SuperAdmin],
    toolTip: "PCB Type",
  },
  {
    linkTo: RoutePaths["PCB Version"],
    image: Images.MenuImages.PcbVersion,
    availableFor: [UserRoles.SuperAdmin],
    toolTip: "PCB Version",
  },
  {
    linkTo: RoutePaths["Assembler"],
    image: Images.MenuImages.Assembler,
    availableFor: [UserRoles.SuperAdmin],
    toolTip: "Assembler",
  },
  {
    linkTo: RoutePaths["Device Register"],
    image: Images.MenuImages.DeviceRegister,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.OnlineTester,
      UserRoles.ExternalTester,
    ],
    toolTip: "Device Register",
  },
  {
    linkTo: RoutePaths["Search House"],
    image: Images.MenuImages.SearchHouse,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.Dispatch,
    ],
    toolTip: "Search House",
  },
  {
    linkTo: RoutePaths["Search Dispatch"],
    image: Images.MenuImages.SearchDispatch,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.Dispatch,
    ],
    toolTip: "Search Dispatch",
  },
  {
    linkTo: RoutePaths["Device Type"],
    image: Images.MenuImages.Assembler,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.Dispatch,
    ],
    toolTip: "Device Type",
  },
  {
    linkTo: RoutePaths["Stock Movement"],
    image: Images.MenuImages.Movement,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Accounts,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.Dispatch,
    ],
    toolTip: "Stock Movement",
  },
  {
    linkTo: RoutePaths["Device Id Pdf"],
    image: Images.MenuImages.DeviceIdsPdf,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Accounts,
      UserRoles.Tester,
      UserRoles.HardwareTester,
      UserRoles.FanModuleTester,
      UserRoles.OnlineTester,
      UserRoles.Dispatch,
    ],
    toolTip: "Device Id Pdf",
  },
  {
    linkTo: RoutePaths["Testing Station"],
    image: Images.MenuImages.SMPSTesting,
    availableFor: [
      UserRoles.SuperAdmin,
      UserRoles.Tester,
      UserRoles.HardwareTester,
    ],
    toolTip: "Testing Station",
  },
];
